import React from 'react'
import './Business.css';
import StarImage from '../../Assets/DigiesimStars.png';
import BannerImg from '../../Assets/DigiesmManImage.png'
import FAQ from '../HomePage/FAQ/FAQ';
import Footer from '../HomePage/Footer/Footer';
import Button from '@mui/material/Button';


const Business = () => {

    return (
        <>
            <div className='Business' >
                <div className="Banner">
                    <div className="s1">
                        <h1>Welcome to our <br /> Business section</h1>
                        <p>
                            Coming soon!
                        </p>
                        <p>For any business query</p>

                        <Button className='contact_button'
                            onClick={() => window.open("https://help.digiesim.com/hc/en-us/requests/new", "_blank")}
                        >
                            Contact Us
                        </Button>
                    </div>

                    <div className="b-img">
                    </div>
                </div>
            </div>
            <div id='searchFaq'>
                <FAQ />
            </div>
            <Footer />
        </>
    )
}

export default Business;