import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Slider.css';
import { StarRateRounded, Verified, VerifiedTwoTone } from '@mui/icons-material';
import Flag from 'react-world-flags';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <ArrowBackIosIcon
            className={className}
            style={{ ...style, display: "block", left: "-20px", color: "black", zIndex: 1 }}
            onClick={onClick}
        />
    );
}

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <ArrowForwardIosIcon
            className={className}
            style={{ ...style, display: "block", right: "-20px", color: "black", zIndex: 1 }}
            onClick={onClick}
        />
    );
}

const SliderCard = () => {
    const settings = {
        className: "center",
        dots: true,
        arrows: true,
        infinite: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "10px",
        initialSlide: 0,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const cards = [
        {
            id: 1,
            rating: 5,
            person: "Sara",
            reviewTitle: "Easy set up",
            reviewContent: "Used it in my recent travels and found it had great coverage and effortless to set up."
        },
        {
            id: 2,
            rating: 5,
            person: "KA",
            reviewTitle: "Would definitely recommend ",
            reviewContent: "Used it in my recent travels and found it had great coverage and effortless to set up."
        },
        {
            id: 3,
            rating: 4,
            person: "Zeina",
            reviewTitle: "Great Coverage",
            reviewContent: "Only sim that actually gives decent coverage. Very happy with it."
        },
        {
            id: 4,
            rating: 5,
            person: "Amz",
            reviewTitle: "Fast setup",
            reviewContent: "I've purchased and installed the eSIM literally while boarding the plane."
        },
        {
            id: 5,
            rating: 5,
            person: "Faiza",
            reviewTitle: "Very efficient",
            reviewContent: "I am glad I came across this service very efficient and trustworthy . Would recommend."
        },
        {
            id: 6,
            rating: 5,
            person: "Moe",
            reviewTitle: "Just WOW !!",
            reviewContent: "Enjoyed using on my trip to Antalya. Excellent service."
        },
    ];


    return (
        <div className="slider-container">
            <h2 className="heading">Join our happy customers</h2>

            <Slider {...settings}>
                {cards.map(card => (
                    <div key={card.id} className="card">

                        <div className="data_card">
                            <div className="rating">
                                <div className="stars">
                                    {Array.from({ length: card.rating }, (_, i) => (
                                        <StarRateRounded key={i} id="star" />
                                    ))}
                                </div>
                                <p>
                                    <Verified id="VerifiedIcon" />
                                    Verified
                                </p>
                            </div>

                            <h2>{card.reviewTitle}</h2>
                            <p>{card.reviewContent}</p>

                            <h3>{card.person} </h3>
                        </div>

                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SliderCard;
