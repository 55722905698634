import React from 'react';
import './FAQ.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { useNavigate } from 'react-router-dom';


const FAQ = () => {

    const navigate = useNavigate()

    const [expanded, setExpanded] = React.useState(false);


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div className='FAQ' >

            <h2>Frequently Asked Questions</h2>

            <div className='FAQ_container'>
                <Accordion id="FAQ_Box" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownIcon style={{ color: '#37BAE4' }} />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{
                            width: '100%', flexShrink: 0, fontSize: '18px', fontWeight: '500', '&:hover': {
                                color: '#37bce5',
                            },
                        }}>
                            Do I replace my current active SIM?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{ fontSize: '14px' }}>
                            <p>No.</p>
                            Smartphones that support ESIM data plans work along side your current network plan. Our eSIM plans are used as an Add-on to your main phone plan and does not provide a phone number for making call or SMS.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion id="FAQ_Box" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownIcon style={{ color: '#37BAE4' }} />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography sx={{
                            width: '100%', flexShrink: 0, fontSize: '18px', fontWeight: '500', '&:hover': {
                                color: '#37bce5',
                            },
                        }}>Do we record any location data?</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{ fontSize: '14px' }}>
                            We do not record ANY device location or web browsing History.  Your browsing and location information is completely private, just as it would be in your native country.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion id="FAQ_Box" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownIcon style={{ color: '#37BAE4' }} />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{
                            width: '100%', flexShrink: 0, fontSize: '18px', fontWeight: '500', '&:hover': {
                                color: '#37bce5',
                            },
                        }}>
                            When can I buy / install my eSIM?
                        </Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{ marginTop: '-4%', fontSize: '14px' }}>
                            You can buy and install your Esim before you travel, the data wont be available until you connect to our partner network.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion id="FAQ_Box" expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownIcon style={{ color: '#37BAE4' }} />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography sx={{
                            width: '100%', flexShrink: 0, fontSize: '18px', fontWeight: '500', '&:hover': {
                                color: '#37bce5',
                            },
                        }}>Does my Device support eSIM?
                        </Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{ marginTop: '-4%', fontSize: '14px' }}>
                            List of Devices supports/Compatible with eSIM
                        </Typography>
                        <Typography variant="h6" component="h6" sx={{ fontSize: '18px', textDecoration: 'underline' }}>iOS</Typography>
                        <Typography variant="h6" component="h6" style={{ marginLeft: '10px', fontSize: '18px' }}>Apple iPhone</Typography>
                        <ul style={{ marginLeft: '35px', fontSize: '14px' }}>
                            <li>iPhone SE 2nd Gen. (2020)</li>
                            <li>iPhone SE 3rd Gen. (2022)</li>
                            <li>iPhone XR</li>
                            <li>iPhone XS</li>
                            <li>iPhone XS Max</li>
                            <li>iPhone 11</li>
                            <li>iPhone 11 Pro</li>
                            <li>iPhone 11 Pro Max</li>
                            <li>iPhone 12</li>
                            <li>iPhone 12 mini</li>
                            <li>iPhone 12 Pro</li>
                            <li>iPhone 12 Pro Max</li>
                            <li>iPhone 13</li>
                            <li>iPhone 13 mini</li>
                            <li>iPhone 13 Pro</li>
                            <li>iPhone 13 Pro Max</li>
                            <li>iPhone 14</li>
                            <li>iPhone 14 Plus</li>
                            <li>iPhone 14 Pro</li>
                            <li>iPhone 14 Pro Max</li>
                            <li>iPhone 15</li>
                            <li>iPhone 15 Plus</li>
                            <li>iPhone 15 Pro</li>
                            <li>iPhone 15 Pro Max</li>

                        </ul>

                        <Typography variant="h6" component="h6" sx={{ fontSize: '18px', textDecoration: 'underline' }}>Android</Typography>
                        <Typography variant="h6" component="h6" style={{ marginLeft: '10px', fontSize: '18px' }}>Google Pixel</Typography>
                        <ul style={{ marginLeft: '35px', fontSize: '14px' }}>
                            <li>Google Pixel 3</li>
                            <li>Google Pixel 3a</li>
                            <li>Google Pixel 3 XL</li>
                            <li>Google Pixel 3a XL</li>
                            <li>Google Pixel 4a</li>
                            <li>Google Pixel 4</li>
                            <li>Google Pixel 4 XL</li>
                            <li>Google Pixel 5</li>
                            <li>Google Pixel 6 / 6a</li>
                            <li>Google Pixel 6 Pro</li>
                            <li>Google Pixel 7 / 7a</li>
                            <li>Google Pixel 7 Pro</li>
                            <li>Google Pixel 8 / 8a</li>
                            <li>Google Pixel 8 Pro</li>

                        </ul>

                        <Typography variant="h6" component="h6" style={{ marginLeft: '10px', fontSize: '18px' }}>Samsung Galaxy</Typography>
                        <ul style={{ marginLeft: '35px', fontSize: '14px' }}>
                            <li>Samsung Galaxy S24 / 5G</li>
                            <li>Samsung Galaxy S24+ / 5G</li>
                            <li>Samsung Galaxy S24 Ultra / 5G</li>
                            <li>Samsung Galaxy S23 / 5G</li>
                            <li>Samsung Galaxy S23+ / 5G</li>
                            <li>Samsung Galaxy S23 Ultra / 5G</li>
                            <li>Samsung Galaxy S22 / 5G</li>
                            <li>Samsung Galaxy S22+ / 5G</li>
                            <li>Samsung Galaxy S22 Ultra / 5G</li>
                            <li>Samsung Galaxy S21 / 5G</li>
                            <li>Samsung Galaxy S21+ / 5G</li>
                            <li>Samsung Galaxy S21 Ultra / 5G</li>
                            <li>Samsung Galaxy S20</li>
                            <li>Samsung Galaxy S20+</li>
                            <li>Samsung Galaxy S20 Ultra</li>
                            <li>Samsung Galaxy Z Flip</li>
                            <li>Samsung Galaxy Z Fold 2</li>
                            <li>Samsung Galaxy Z Fold 3 5G</li>
                            <li>Samsung Galaxy Z Flip 3 5G Fold</li>
                            <li>Samsung Galaxy Z Flip 3 5G</li>
                            <li>Samsung Galaxy Z Flip 4</li>
                            <li>Samsung Galaxy Z Fold 4</li>
                            <li>Samsung Galaxy Z Flip 5</li>
                            <li>Samsung Galaxy Z Fold 5</li>
                            <li>Samsung Galaxy A55 5G</li>
                            <li>Samsung Galaxy A54 5G</li>
                            <li>Samsung Galaxy A34 5G</li>
                            <li>Samsung Galaxy Fold</li>
                            <li>Samsung Galaxy Note20</li>
                            <li>Samsung Galaxy Note20 Ultra</li>
                            <li>NOTE: Samsung Galaxy "FE" models are NOT compatible.</li>
                        </ul>

                        <Typography variant="h6" component="h6" style={{ marginLeft: '10px', fontSize: '18px' }}>Huawei</Typography>
                        <ul style={{ marginLeft: '35px', fontSize: '14px' }}>
                            <li>Huawei P40</li>
                            <li>Huawei P40 Pro</li>
                            <li>Huawei Mate 40 Pro</li>
                        </ul>

                        <Typography variant="h6" component="h6" style={{ marginLeft: '10px', fontSize: '18px' }}>Motorola Razr</Typography>
                        <ul style={{ marginLeft: '35px', fontSize: '14px' }}>
                            <li>Motorola Razr</li>
                            <li>Motorola Razr 5G</li>
                        </ul>

                        <Typography variant="h6" component="h6" style={{ marginLeft: '10px', fontSize: '18px' }}>Oppo</Typography>
                        <ul style={{ marginLeft: '35px', fontSize: '14px' }}>
                            <li>Oppo Find N2 Flip</li>
                            <li>Oppo Find X3 Pro</li>
                            <li>Oppo Find X5</li>
                            <li>Oppo Find X5 Pro</li>
                            <li>Oppo Reno 5 A</li>
                            <li>Oppo A55s 5G</li>
                            <li>Oppo Reno6 Pro 5G</li>
                        </ul>

                        <Typography variant="h6" component="h6" style={{ marginLeft: '10px', fontSize: '18px' }}>Nokia</Typography>
                        <ul style={{ marginLeft: '35px', fontSize: '14px' }}>
                            <li>Nokia G60 5G</li>
                            <li>Nokia X30 5G</li>
                            <li>Nokia XR21</li>
                        </ul>

                        <Typography variant="h6" component="h6" style={{ marginLeft: '10px', fontSize: '18px' }}>Xiaomi</Typography>
                        <ul style={{ marginLeft: '35px', fontSize: '14px' }}>
                            <li>Xiaomi 12T Pro</li>
                            <li>Xiaomi 13</li>
                            <li>Xiaomi 13 Lite</li>
                            <li>Xiaomi 13 Pro</li>
                        </ul>

                        <Typography variant="h6" component="h6" style={{ marginLeft: '10px', fontSize: '18px' }}>Honor</Typography>
                        <ul style={{ marginLeft: '35px', fontSize: '14px' }}>
                            <li>Honor 90</li>
                            <li>Honor Magic 3</li>
                            <li>Honor Magic 3 Pro</li>
                            <li>Honor Magic 3 Pro+</li>
                            <li>Honor Magic 4 Pro</li>
                        </ul>

                        <Typography variant="h6" component="h6" style={{ marginLeft: '10px', fontSize: '18px' }}>Microsoft</Typography>
                        <ul style={{ marginLeft: '35px', fontSize: '14px' }}>
                            <li>Surface Duo 2</li>
                            <li>Surface Duo</li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
            </div>

            <div className='End-section'>
                <a href="https://help.digiesim.com/hc/en-us" target='_blank' >
                    <Button variant="contained">
                        <ContactSupportIcon></ContactSupportIcon> More FAQs
                    </Button>
                </a>
            </div>

        </div>
    )
}

export default FAQ
