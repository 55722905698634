import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PaymentSuccess.css";
import "./PaymentCancel.css"
import paymentfaild from '../../Assets/payment_faild.jpg'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';



const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    minWidth:"350px",
    minHeight:'300px',
    transform: "translate(-50%, -50%)",
      bgcolor:"#FFFFFF",
      borderRadius:"10px"
};

const PaymentCancel = () => {
    const [openBox, setOpenBox] = useState(true);
    const navigate = useNavigate();

    const handleHomenavigate = () => {
        navigate('/payment')
    }



    return (
        <>
            {/* <div className="PaymentSuccess">
            </div> */}
            <Modal
                open={openBox}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="payment_failed">
                    <h2>Try Again   
                        <HighlightOffIcon   onClick={handleHomenavigate} style={{ color: 'Red', width: '50px', height: '50px', marginLeft: '8px' ,cursor:'pointer'}} />
                        </h2>
                    
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%' }}>
                        <Button
                            style={{ color: "#FFFFFF", transform: "scale(.9)" , background:'#00a1e4', fontSize:'22px', fontWeight:'700', width:'70%', borderRadius:'10px'}}
                            variant="outlined"
                            // color="inherit"
                            onClick={handleHomenavigate}
                        >
                            Close
                        </Button>
                    </div>

                </Box>
            </Modal>
        </>
    );
};



export default PaymentCancel;
