import React, { useState, useEffect } from 'react';
import { IconButton, Box, Button, TextField, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Account.css';
import Footer from '../HomePage/Footer/Footer';
import { Accordion, AccordionSummary, AccordionDetails, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Account = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const UserUuid = localStorage.getItem("UserUuid");
    const ICCID = localStorage.getItem('ICCID');
    const plan_id = localStorage.getItem("plan_id");


    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [NewPasswordFlag, setNewPasswordFlag] = useState(false);
    const [seePassword, setSeePassword] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [reTypeViewPassword, setRetypeViewPassword] = useState(false);
    const [reTypePassword, setReTypePassword] = useState("")
    const [value, setValue] = useState("4");

    const [paymentHistory, setPaymentHistory] = useState([]);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailId, setEmailId] = useState("");
    const [iccid, setIccid] = useState("");

    // data history get_plan Api

    const [totalDataHistory, setTotalDataHistory] = useState(0);
    const [totalRemaningDataHistory, setTotalRemaningDataHistory] = useState(0);


    const handleNewPasswordVisibility = () => {
        setViewPassword(!viewPassword);
    };

    const handleCurrentPasswordVisibility = () => {
        setSeePassword(!seePassword);
    };

    const handleReTypePasswordVisibility = () => {
        setRetypeViewPassword(!reTypeViewPassword)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    const showUserDetails = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/user_details`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setFirstName(result.accounts.first_name);
                setLastName(result.accounts.last_name);
                setEmailId(result.accounts.email_id);
                setIccid(result.accounts.iccid)
                localStorage.setItem("Iccid", result.accounts.iccid);
            })
            .catch((error) => console.error(error));
    }



    useEffect(() => {
        showUserDetails()
    }, [])


    const handlePasswordEdit = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            user_uuid: UserUuid,
            password: currentPassword,
            confirm_password: newPassword,
            retype_confirm_password: reTypePassword,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            `${REACT_APP_BASE_URL}/change_password`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === 1) {
                    toast(result.Msg, {
                        position: "top-right",
                        autoClose: 2000,
                    });
                    setCurrentPassword("");
                    setNewPassword("");
                    setReTypePassword("")
                } else {
                    toast(result.Msg, {
                        position: "top-right",
                        autoClose: 2000,
                    });
                }
                setCurrentPassword("");
                setNewPassword("");
                setReTypePassword("")
            });
    };


    const ShowPaymentHistory = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${REACT_APP_BASE_URL}/payment_history`, requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setPaymentHistory(result.payment_history || []);

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    useEffect(() => {
        ShowPaymentHistory()
        ShowDataHistory()
    }, [])


    const ShowDataHistory = () => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic Og==");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`https://test.digiesim.com:10001/get_plan/${ICCID}/${plan_id}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                // setTotalDataHistory((result.plan.data_quota_bytes) / (1024 * 1024)).toFixed(2)
                // setTotalRemaningDataHistory((result.plan.data_bytes_remaining) / (1024 * 1024)).toFixed(2)

                setTotalDataHistory(((result.plan.data_quota_bytes) / (1024 * 1024)).toFixed(2))
                setTotalRemaningDataHistory(((result.plan.data_bytes_remaining) / (1024 * 1024)).toFixed(2))

            })
            .catch(error => console.error('Error fetching data:', error));
    }



    const convertEpochToDate = (epochTime) => {
        const date = new Date(epochTime * 1000);
        return date.toLocaleString();
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <>
            <div className="container">
                <h1>My digiesim</h1>
                <section className="profile">
                    <div className="profile-picture">
                        <div className="initials"> {firstName.charAt(0)}{lastName.charAt(0)}</div>
                    </div>
                    <div className="profile-info">
                        <div className="profile-item">
                            <p>Name:</p>
                            <span> {firstName} {lastName}</span>
                        </div>
                        <div className="profile-item">
                            <p>Email:</p>
                            <span>{emailId}</span>
                        </div>
                        <div className="profile-item">
                            <p>eSIM Id (ICCID):</p>
                            <span>{iccid ? iccid : "N/A"}</span>
                        </div>
                        <div className="profile-item">
                            <p>Total Data :</p>
                            <span>
                                {totalDataHistory} MB
                            </span>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    width: "100%",
                                    height: "20px",
                                    padding: "0px",
                                    marginBottom: "5px",
                                    padding: "0px 5px",
                                }}
                            >
                                <p>Data Consumed</p>
                                <p>Data Remaining</p>
                            </div>

                            <div style={{ display: "flex", width: "100%", borderRadius: '10px', overflow: 'hidden', border: '1px lightgrey solid', backgroundColor: "#e0e1dd" }}>
                                <div
                                    style={{
                                        backgroundColor: "#778da9",
                                        borderTopLeftRadius: "10px",
                                        borderBottomLeftRadius: "10px",
                                        height: "12px",
                                        animation: "progressBar 1s ease-in-out forwards",
                                        width: `${((totalDataHistory - totalRemaningDataHistory) / totalDataHistory) * 100}%`,
                                    }}
                                ></div>
                                <div
                                    style={{
                                        backgroundColor: "#e0e1dd",
                                        borderTopRightRadius: "10px",
                                        borderBottomRightRadius: "10px",
                                        height: "12px",
                                        animation: "progressBar 1s ease-in-out forwards",
                                        width: `${(totalRemaningDataHistory / totalDataHistory) * 100}%`,
                                    }}
                                ></div>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    fontWeight: "300",
                                    fontSize: "14px",
                                    width: "100%",
                                    height: "20px",
                                    marginTop: '3px',
                                    padding: "0px 5px",
                                }}
                            >
                                <p>{(totalDataHistory - totalRemaningDataHistory)} MB </p>
                                <p>{(totalRemaningDataHistory)} MB </p>
                            </div>
                        </div>

                    </div>
                </section>
                <Box
                    sx={{ width: "100%", typography: "body1" }}
                    style={{
                        backgroundColor: "white",
                        padding: "10px",
                        borderRadius: "10px",
                    }}
                >
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                            >
                                <Tab label="PLAN" value="4" />
                                <Tab label="PAYMENTS" value="3" />
                                <Tab label="PASSWORD" value="1" />


                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <div style={{ padding: '10px' }}>
                                <Typography>
                                    <h2
                                        style={{
                                            color: "#00a1e4",
                                            fontWeight: "500",
                                            fontSize: "22px",
                                        }}
                                    >
                                        Change Password
                                    </h2>
                                    <br />
                                    <div>
                                        <TextField
                                            className='updatePassword'
                                            type={seePassword ? "text" : "password"}
                                            label="Current Password"
                                            value={currentPassword}
                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end" onClick={handleCurrentPasswordVisibility}>
                                                            {seePassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <div className='upadtePas' >
                                            <TextField
                                                style={{
                                                    width: "100%",
                                                    padding: "0px",
                                                }}
                                                type={viewPassword ? "text" : "password"}
                                                label="New Password"

                                                value={newPassword}
                                                onChange={(e) => {
                                                    setNewPassword(e.target.value);
                                                    setNewPasswordFlag(e.target.value.length > 7);
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton edge="end" onClick={handleNewPasswordVisibility}>
                                                                {viewPassword ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>

                                        <div className='upadtePas' >
                                            <TextField
                                                style={{
                                                    width: "100%",
                                                    padding: "0px",
                                                }}
                                                type={reTypeViewPassword ? "text" : "password"}
                                                label="Re-Type Password"

                                                value={reTypePassword}
                                                onChange={(e) => {
                                                    setReTypePassword(e.target.value);
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton edge="end" onClick={handleReTypePasswordVisibility}>
                                                                {reTypeViewPassword ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>

                                    </div>
                                    <br />
                                    <Button className='updatebtn'
                                        style={{
                                            backgroundColor: "#00a1e4",
                                            color: "white",
                                        }}
                                        onClick={handlePasswordEdit}
                                    >
                                        Edit Password
                                    </Button>
                                </Typography>
                            </div>
                        </TabPanel>


                        <TabPanel value="3">
                            <div>
                                {paymentHistory.length === 0 ? (
                                    <Typography variant="body2" color="textSecondary">No payment history available.</Typography>
                                ) : (
                                    paymentHistory.map((payment) => (
                                        <Accordion key={payment.plan_name} style={{}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{ background: 'linear-gradient(105deg, #ddd 69%, rgb(91 187 227) 69%)', marginTop: '10px' }}
                                            >
                                                <AccountBalanceWalletIcon style={{ marginRight: '10px', marginTop: '15px', color: 'rgb(91 187 227)', }} />
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography variant="h6">${payment.price}</Typography>
                                                    <Typography variant="body2">Plan Name: {payment.plan_name}</Typography>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className='Accordion_body'>
                                                    <div>
                                                        <Typography variant="body2" style={{ marginTop: '10px' }}>Payment Method: {capitalizeFirstLetter(payment.payment_method)}</Typography>
                                                        <Typography variant="body2" style={{ marginTop: '10px' }}>Date: {convertEpochToDate(payment.created)}</Typography>
                                                    </div>
                                                    <div>
                                                        <Typography variant="body2" style={{ marginTop: '10px' }}>{payment.receipt_email}</Typography>
                                                        <Typography variant="body2" style={{ marginTop: '10px' }}>Status: {capitalizeFirstLetter(payment.status)}</Typography>

                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                                )}
                            </div>
                        </TabPanel>

                        <TabPanel value="4">
                            <div className='PlanName_main' style={{ padding: '10px', }}>
                                {paymentHistory.length === 0 ? (
                                    <Typography variant="body2" color="textSecondary">No Plan available.</Typography>
                                ) : (
                                    paymentHistory.map((payment) => (
                                        <div className="PlanName">
                                            <p className='heading'>Plan Name: {payment.plan_name}</p>

                                            <p>Price:  $ {payment.price}</p>
                                            <p style={{}}>Purchase Date: {convertEpochToDate(payment.created)}</p>
                                        </div>
                                    ))
                                )}
                            </div>
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>



            <Footer />

        </>
    );
};

export default Account;
