import React, { useEffect, useState } from 'react';
import './SelectedCountryPlan.css';
import Flag from 'react-world-flags';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Modal, OutlinedInput } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { H2 } from "../Typography";
import { Bars } from 'react-loader-spinner';
import NoPlanImg from '../../Assets/Empty.gif';
import Logo_4 from '../../Assets/Logo_4.png';
import { getAllInfoByISO } from 'iso-country-currency';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import DuoIcon from '@mui/icons-material/Duo';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ClearIcon from '@mui/icons-material/Clear';
import { countries } from 'country-data';
import FAQ from '../HomePage/FAQ/FAQ';
import Footer from '../HomePage/Footer/Footer';
import CheckIcon from '@mui/icons-material/Check';
import SimCardIcon from '@mui/icons-material/SimCard';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#FFF",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
    // width: window.innerWidth <= 650 ? '90%' : 'auto',
};

const SelectedCountryPlan = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const SelectedCountry = localStorage.getItem('SelectedCountry');
    const UserUuid = localStorage.getItem("UserUuid");



    const [PlanType, setPlanType] = useState("5");
    const [PlansList, setPlansList] = useState([]);
    const [NetworkName, setNetworkName] = useState("");
    const [FiveGnetwork, setFiveGnetwork] = useState("");
    const [FourGnetwork, setFourGnetwork] = useState("");
    const [PlansListShowLoader, setPlansListShowLoader] = useState("");

    const IncludedPageLink = "https://help.digiesim.com/hc/en-us/articles/27446148143377-What-s-Included";
    const HelpCentrePageLink = "https://help.digiesim.com/hc/en-us";


    const handleChange = (event, newValue) => {
        setPlanType(newValue);
        setPlansListShowLoader("");
    };

    const ShowPlans = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "days": PlanType,
            "iso": SelectedCountry
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/getAllPlanTypeByDays`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setPlansList(result.plan_type_datas);
                setPlansListShowLoader(result.result);
                setNetworkName(result.network_list_response.networks[0].network_name);
                setFiveGnetwork(result.network_list_response.networks[0].support_5g)
                setFourGnetwork(result.network_list_response.networks[0].support_lte)


            });
    };

    useEffect(() => {
        ShowPlans();
    }, [PlanType]);







    const handleNavigate = (uid) => {
        if (UserUuid) {
            localStorage.setItem("PlanUID", uid);
            navigate('/payment');
            window.scrollTo(0, 0);
        } else {
            localStorage.setItem("redirectAfterLogin", '/payment');
            localStorage.setItem("PlanUID", uid);
            setShowModal(true);  // Show the modal
        }
    };


    const handleCloseModal = () => {
        setShowModal(false);
    };

    function getCountryName(iso3Code) {
        const country = countries[iso3Code];
        return country ? country.name : "Unknown country code";
    }

    const getCurrencySymbol = (iso3Code) => {
        try {
            const countryInfo = getAllInfoByISO(iso3Code);
            return countryInfo.currency.symbol;
        } catch (error) {
            return '';
        }
    };




    const currencySymbol = getCurrencySymbol(SelectedCountry);

    const getPlanHeading = (dataQuotaMb) => {
        const dataQuotaGb = dataQuotaMb / 1024;
        if (dataQuotaGb <= 0.5) {
            return "Get Connected";
        } else if (dataQuotaGb > 0.5 && dataQuotaGb <= 10) {
            return "Value Plus";
        } else if (dataQuotaGb > 10 && dataQuotaGb <= 39) {
            return "Stream Happy";
        } else if (dataQuotaGb > 39) {
            return "Go Unlimited";
        } else {
            return "Get Connected";
        }
    };
    const [getConnectedModalOpen, setGetConnectedModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("Get Connected");
    const handleOpenModal = (title) => {
        setModalTitle(title);
        setGetConnectedModalOpen(true);
    };

    const handleCloseModals = () => {
        setGetConnectedModalOpen(false);
    };
    const getFeaturesList = (dataQuotaMb) => {
        const dataQuotaGb = dataQuotaMb / 1024;
        let title = "Get Connected";

        if (dataQuotaGb <= 0.5) {
            title = "Get Connected";
            return [
                { icon: <Brightness7Icon style={{ marginRight: '8px' }} />, text: "Zero hassle" },
                { icon: <CurrencyExchangeIcon style={{ marginRight: '8px' }} />, text: "Unbeatable Price" },
                { icon: <SettingsInputAntennaIcon style={{ marginRight: '8px' }} />, text: "Stay Connected" },
                { icon: <MobileScreenShareIcon style={{ marginRight: '8px' }} />, text: "Add data if needed" },
                {
                    icon: (
                        <div className='What_Included'

                            onClick={() => handleOpenModal(title)}
                        >
                            <AddTaskIcon className='Icon' style={{ color: 'indianred' }} />
                            <span >What's Included</span>
                        </div>
                    ),
                }
            ];
        } else if (dataQuotaGb > 0.5 && dataQuotaGb <= 10) {
            title = "Value Plus";
            return [
                { icon: <VerifiedUserIcon style={{ marginRight: '8px' }} />, text: "Amazing value" },
                { icon: <WhatsAppIcon style={{ marginRight: '8px' }} />, text: "Great for WhatsApp" },
                { icon: <WifiTetheringIcon style={{ marginRight: '8px' }} />, text: "Hotspot data" },
                { icon: <MobileScreenShareIcon style={{ marginRight: '8px' }} />, text: "Add data if needed" },
                {
                    icon: (
                        <div className='What_Included'

                            onClick={() => handleOpenModal(title)}
                        >
                            <AddTaskIcon className='Icon' style={{ color: 'indianred' }} />
                            <span >What's Included</span>
                        </div>
                    ),
                }
            ];
        } else if (dataQuotaGb > 10 && dataQuotaGb <= 39) {
            title = "Stream Happy";
            return [
                { icon: <CurrencyExchangeIcon style={{ marginRight: '8px' }} />, text: "Great for streaming" },
                { icon: <MovieCreationIcon style={{ marginRight: '8px' }} />, text: "Enough for reels" },
                { icon: <WifiTetheringIcon style={{ marginRight: '8px' }} />, text: "Hotspot data" },
                { icon: <MobileScreenShareIcon style={{ marginRight: '8px' }} />, text: "Add data if needed" },
                {
                    icon: (
                        <div className='What_Included'

                            onClick={() => handleOpenModal(title)}
                        >
                            <AddTaskIcon className='Icon' style={{ color: 'indianred' }} />
                            <span >What's Included</span>
                        </div>
                    ),
                }
            ];
        } else if (dataQuotaGb > 39) {
            title = "Go Unlimited";
            return [
                { icon: <CurrencyExchangeIcon style={{ marginRight: '8px' }} />, text: "Unleash your data" },
                { icon: <CastConnectedIcon style={{ marginRight: '8px' }} />, text: "Stream socials" },
                { icon: <Brightness7Icon style={{ marginRight: '8px' }} />, text: "Hassle free" },
                { icon: <DuoIcon style={{ marginRight: '8px' }} />, text: "Great for video calling" },
                {
                    icon: (
                        <div className='What_Included'

                            onClick={() => handleOpenModal(title)}
                        >
                            <AddTaskIcon className='Icon' style={{ color: 'indianred' }} />
                            <span >What's Included</span>
                        </div>
                    ),
                }
            ];
        } else {
            return [
                { icon: <Brightness7Icon style={{ marginRight: '8px' }} />, text: "Zero hassle" },
                { icon: <CurrencyExchangeIcon style={{ marginRight: '8px' }} />, text: "Unbeatable Price" },
                { icon: <SettingsInputAntennaIcon style={{ marginRight: '8px' }} />, text: "Stay Connected" },
                { icon: <MobileScreenShareIcon style={{ marginRight: '8px' }} />, text: "Add data if needed" },
                {
                    icon: (
                        <div className='What_Included'

                            onClick={() => handleOpenModal(title)}
                        >
                            <AddTaskIcon className='Icon' style={{ color: 'indianred' }} />
                            <span >What's Included</span>
                        </div>
                    ),
                }
            ];
        }
    };

    return (
        <>
            <div className='SelectedCountryPlan'>
                <div className='one'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Flag code={SelectedCountry} id='SelectedCountryFlag' />
                        <h1>{getCountryName(SelectedCountry)} eSIM <span style={{ color: '#EA857A', }}>Plans & Pricing</span> </h1>
                    </div>
                    <p>Pick your plan duration, go Prepaid or Unlimited, and get ready to connect!</p>
                </div>

                <div className='two'>
                    <TabContext value={PlanType}>
                        <Box id="tabs">
                            <TabList onChange={handleChange}>
                                <Tab label="5 Days" value="5" />
                                <Tab label="10 Days" value="10" />
                                <Tab label="15 Days" value="15" />
                                <Tab label="30 Days" value="30" />
                            </TabList>
                        </Box>

                        <TabPanel value={PlanType} className='tabPanel' id='tabPanelOne'>
                            {PlansListShowLoader ? (
                                PlansList == null || PlansList.length === 0 ? (
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={NoPlanImg} id='NoPlanImg' />
                                        <H2 style={{ margin: '10px', fontWeight: '300' }}>
                                            There is no such plan currently.
                                        </H2>
                                    </div>
                                ) : (
                                    // Sorting the PlansList based on the heading
                                    PlansList.sort((a, b) => {
                                        const headingPriority = {
                                            "Get Connected": 1,
                                            "Value Plus": 2,
                                            "Stream Happy": 3,
                                            "Go Unlimited": 4
                                        };

                                        const headingA = getPlanHeading(a.data_quota_mb);
                                        const headingB = getPlanHeading(b.data_quota_mb);

                                        return (headingPriority[headingA] || 0) - (headingPriority[headingB] || 0);
                                    }).map((plan, index) => {
                                        const features = getFeaturesList(plan.data_quota_mb);
                                        return (
                                            <div key={index} className='Card'>
                                                <div className='Card1'>
                                                    <div id="Card1_1" className="1">
                                                        <h1>{getPlanHeading(plan.data_quota_mb)}</h1>
                                                    </div>
                                                </div>
                                                <div className="Card2">
                                                    <div id='Card2_1'>
                                                        <Flag code={SelectedCountry} id='Card1Flag' />
                                                    </div>
                                                    <div id='Card2_2'>
                                                        <h2>{getCountryName(SelectedCountry)}</h2>
                                                        {/* <h2>{plan.name}</h2> */}


                                                    </div>
                                                    <h2>
                                                        {
                                                            plan.plan_type
                                                                ? `${plan.plan_type}`
                                                                : `${plan.data_quota_mb < 1024 ? `${plan.data_quota_mb} MB` : `${(plan.data_quota_mb / 1024).toFixed(0)} GB`}`
                                                        }


                                                    </h2>
                                                </div>
                                                <div className="Card3">
                                                    <div className='features'>
                                                        {features.map((feature, idx) => (
                                                            <h2 key={idx} className='feature-item'>
                                                                {feature.icon}
                                                                {feature.text}
                                                            </h2>
                                                        ))}
                                                    </div>
                                                    <center style={{ display: 'flex', padding: '20px' }}>
                                                        <Button
                                                            style={{
                                                                fontSize: "25px",
                                                                fontWeight: '700',
                                                                color: "#000",
                                                                backgroundColor: "#D6F0F6",
                                                                borderRadius: "10px 0 0 10px",
                                                                padding: '6px 18px',
                                                                width: '30%',
                                                                cursor: 'inherit'
                                                            }}
                                                        >
                                                            ${plan.price}
                                                        </Button>

                                                        <Button
                                                            style={{
                                                                fontSize: "25px",
                                                                fontWeight: '700',
                                                                color: "white",
                                                                backgroundColor: "#5CA7E0",
                                                                borderRadius: "0px 10px 10px 0px",
                                                                padding: '6px 18px',
                                                                width: '70%',
                                                                textTransform: 'capitalize'
                                                            }}
                                                            onClick={() => handleNavigate(plan.uid)}
                                                        >
                                                            Buy Now
                                                        </Button>
                                                    </center>
                                                    <div className='end-sec'>
                                                        {/* <img src={Logo_4} id='tur-logo' /> */}

                                                        <h2> <SignalCellularAltIcon className='sim_icon' /> <p>{NetworkName}</p> </h2>
                                                        {/* <h1 >5<span style={{ fontSize: '26px' }}>G</span></h1> */}
                                                        <div>
                                                            {FiveGnetwork === 1 && FourGnetwork === 1 ? (
                                                                <h1>
                                                                    5<span style={{ fontSize: '26px' }}>G</span>
                                                                </h1>
                                                            ) : FourGnetwork === 1 && FiveGnetwork === 0 ? (
                                                                <h1>4<span style={{ fontSize: '26px' }}>G</span></h1>
                                                            ) : (
                                                                <h1 style={{fontSize:'22px'}}>No Network</h1>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                )
                            ) : (
                                <div style={{ width: '100%', height: '20vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Bars
                                        height="30"
                                        width="30"
                                        color="#EA857A"
                                        visible={true}
                                    />
                                </div>
                            )}
                        </TabPanel>

                    </TabContext>
                </div>

                <Modal
                    open={showModal}
                    onClose={handleCloseModal}
                >
                    <Box sx={style} className="redirect_modal" >
                        <h2 >Login Required</h2>
                        <p >You need to log in to proceed with the buy.</p>
                        <Button variant="contained" color="primary" onClick={() => navigate('/login')}>
                            Go to Login
                        </Button>
                    </Box>
                </Modal>



                <Modal
                    open={getConnectedModalOpen}
                    onClose={handleCloseModals}
                >
                    <Box sx={style} className="modal-box">
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '13px' }}>
                            <h3 style={{ fontWeight: '700', fontSize: '24px', background: '#E7EB14', padding: '8px', borderRadius: '5px' }}>{modalTitle} </h3>
                            <ClearIcon style={{ cursor: 'pointer', color: 'indianred' }} onClick={handleCloseModals} />
                        </div>

                        {/* <hr /> */}

                        <div className='IncludedModal'>
                            {(modalTitle === 'Get Connected' || modalTitle === 'Value Plus' || modalTitle === 'Stream Happy') && (
                                <div className="box1">
                                    <h2> What’s Included:</h2>

                                    <div id='info' >
                                        <CheckIcon className='icon' />
                                        <a href={IncludedPageLink} target='_blank' > Purchase with confidence with our <span> Money back guarantee policy. </span> </a>
                                    </div>
                                    <div id='info'>
                                        <CheckIcon className='icon' /> Install your eSIM via the QR code that is emailed to you
                                        .</div>
                                    <div id='info'>
                                        <CheckIcon className='icon' /> Set an auto top up if you need more data.</div>
                                    <div id='info'>
                                        <CheckIcon className='icon' />
                                        <a href={IncludedPageLink} target='_blank' >
                                            <span>Find out more</span> about our plans at the  <a href={HelpCentrePageLink} target='_blank' > <span>help centre. </span></a> </a>
                                    </div>
                                    <div id='info'>
                                        <CheckIcon className='icon' />
                                        <a href={HelpCentrePageLink} target='_blank' >  24/7 Support via our <span>help centre.</span></a> </div>
                                </div>
                            )}

                            {modalTitle === 'Go Unlimited' && (
                                <div className="box1">
                                    <h2> What’s Included:</h2>
                                    <div id='info'> <CheckIcon className='icon' />
                                        <a href={IncludedPageLink} target='_blank' > Purchase with confidence with our <span> Money back guarantee policy. </span> </a>
                                    </div>
                                    <div id='info'>
                                        <CheckIcon className='icon' />
                                        Set an auto top up if you need more data.
                                    </div>
                                    <div id='info'>
                                        <CheckIcon className='icon' />
                                        <a href={IncludedPageLink} target='_blank' > After 2GB of daily high speed data, use UNLIMITED LTE data of 1Mbps. <span>Find out more.</span> </a>
                                    </div>
                                    <div id='info'>
                                        <CheckIcon className='icon' />
                                        <a href={IncludedPageLink} target='_blank' > Unlimited plans are subject to our <span>Fair Use Policy.</span>  </a>
                                    </div>
                                    <div id='info'>
                                        <CheckIcon className='icon' /> Install your eSIM via the QR code that us emailed to you.
                                    </div>
                                    <div id='info'>
                                        <CheckIcon className='icon' />
                                        <a href={IncludedPageLink} target='_blank' >
                                            <span>Find out more</span> about our plans on our  <a href={HelpCentrePageLink} target='_blank' > <span>help centre. </span></a> </a>
                                    </div>


                                    <div id='info'>
                                        <CheckIcon className='icon' />
                                        <a href={HelpCentrePageLink} target='_blank' >  24/7 Support via our <span>help centre.</span></a>
                                    </div>
                                </div>
                            )}
                        </div>

                    </Box>

                </Modal>
            </div >
            <div id='searchFaq'>
                <FAQ />
            </div>
            <Footer />
        </>
    );
};

export default SelectedCountryPlan;
