import React, { useEffect, useState, useRef } from 'react';
import "./Payment.css"
import Flag from 'react-world-flags';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import CancelIcon from '@mui/icons-material/Cancel';
import GooglePayButton from '@google-pay/button-react';
import Logo from '../../../Assets/digiesim.png';
import FAQ from '../FAQ/FAQ';
import Footer from '../Footer/Footer';
import {
    Button,
    FormControl,
    FormControlLabel,
    Typography,
    Checkbox,
    Box,
    Modal,
    TextField
} from '@mui/material';
import paypalImage from '../../../Assets/paypal.png'
import gpay from '../../../Assets/gpay.jpeg'
import card from '../../../Assets/DebitCard.jpg'
import { loadStripe } from '@stripe/stripe-js';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


// const stripePromise = loadStripe('pk_test_51P0af3RwMY7Q8gI5CpEAEpmiNP0Ei6Ag8qIqmMrupp2qdJIqi2wXcJNYKyLXpJXTQ9AAYP7lVjWcIwbSBmDZVgDf00HQGfLgWM');
// const stripePromise = loadStripe('pk_live_51P0af3RwMY7Q8gI54pMdIH2ovbLsv32IkbJFZx1JaedonDeOerr1LUoMNhsD5nHxKo2pdUpHf0TNIkVceOKLGkED00EiMhMacE')
const Payment = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const UserUuid = localStorage.getItem('UserUuid')
    const PlanUID = localStorage.getItem("PlanUID")

    const countryData = require('country-data');

    function getCountryName(iso3Code) {
        const country = countryData.countries[iso3Code];
        return country ? country.name : "Unknown country code";
    }

    const [PlanName, setPlanName] = useState("")
    const [PlanData, setPlanData] = useState("")
    const [PlanValidity, setPlanValidity] = useState("")
    const [PlanPrice, setPlanPrice] = useState("")
    const [CountryIso3Code, setCountryIso3Code] = useState("")
    const [OpenPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);
    const [OpenCouponModal, setOpenCouponModal] = useState(false);

    const handleOpen = () => { setOpenPaymentMethodModal(true) }
    const handleClose = () => setOpenPaymentMethodModal(false);
    const handleApplyOpen = () => setOpenCouponModal(true);
    const handleApplyClose = () => setOpenCouponModal(false)

    const ShowCardData = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "uid": PlanUID
        });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        fetch(`${REACT_APP_BASE_URL}/get_plan_details`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setPlanName(result.plan_type_datas[0].name)
                setPlanData(result.plan_type_datas[0].data_quota_mb)
                setPlanValidity(result.plan_type_datas[0].validity_days)
                setPlanPrice(result.plan_type_datas[0].price)
                setCountryIso3Code(result.plan_type_datas[0].countries_enabled)

                localStorage.setItem("BillingCountry", result.plan_type_datas[0].countries_enabled)
                localStorage.setItem("BillingDays", result.plan_type_datas[0].validity_days)
                localStorage.setItem("BillingData", result.plan_type_datas[0].data_quota_mb)
                localStorage.setItem("BillingPrice", result.plan_type_datas[0].price);
                localStorage.setItem("BillingPlan", result.plan_type_datas[0].name)
                localStorage.setItem("BillingUid", result.plan_type_datas[0].uid)
                localStorage.setItem("PlanType", result.plan_type_datas[0].plan_type)
            })
    }

    

    useEffect(() => {
        ShowCardData()
    }, [])

    // const makePayment = async () => {
    //     const stripe = await loadStripe("pk_test_51P0af3RwMY7Q8gI5CpEAEpmiNP0Ei6Ag8qIqmMrupp2qdJIqi2wXcJNYKyLXpJXTQ9AAYP7lVjWcIwbSBmDZVgDf00HQGfLgWM")
    //         // const stripe = await loadStripe("pk_live_51P0af3RwMY7Q8gI54pMdIH2ovbLsv32IkbJFZx1JaedonDeOerr1LUoMNhsD5nHxKo2pdUpHf0TNIkVceOKLGkED00EiMhMacE")

    //     const body = {
    //         "user_uuid": UserUuid,
    //         "price": PlanPrice,
    //         "plan_name": PlanName
    //     }

    //     const header = {
    //         "Content-Type": "application/json",
    //     }

    //     const response = await fetch(`${REACT_APP_BASE_URL}/payement_stripe`, { method: "POST", headers: header, body: JSON.stringify(body) })

    //     const session = await response.json();

    //     localStorage.setItem('SessionId', session.session_url.session_id)

    //     const result = stripe.redirectToCheckout({ sessionId: session.session_url.session_id });
        
        

    // }


    const makePayment = async () => {
        // const stripe = await loadStripe("pk_test_51P0af3RwMY7Q8gI5CpEAEpmiNP0Ei6Ag8qIqmMrupp2qdJIqi2wXcJNYKyLXpJXTQ9AAYP7lVjWcIwbSBmDZVgDf00HQGfLgWM");
        // const stripe = await loadStripe("pk_live_51P0af3RwMY7Q8gI54pMdIH2ovbLsv32IkbJFZx1JaedonDeOerr1LUoMNhsD5nHxKo2pdUpHf0TNIkVceOKLGkED00EiMhMacE");
    
        const body = {
            "user_uuid": UserUuid,
            "price": PlanPrice,
            "plan_name": PlanName
        };
    
        const header = {
            "Content-Type": "application/json",
        };
    
        const response = await fetch(`${REACT_APP_BASE_URL}/payement_stripe`, { method: "POST", headers: header, body: JSON.stringify(body) });
    
        const session = await response.json();
        // const result = stripe.redirectToCheckout({ sessionId: session.session_url.session_id });
    
        if (session && session.session_url && session.session_url.session_url) {
            // Store the session ID in localStorage if needed
            localStorage.setItem('SessionId', session.session_url.session_id);
    
            // Redirect the browser to the session URL
            window.location.href = session.session_url.session_url;
        } else {
            // Handle the case where the session URL is not returned
            console.error('Session URL not found in the response.');
        }
    };
    



    const expressCheckoutRef = useRef(null);

    useEffect(() => {
        const countryName = getCountryName(CountryIso3Code);
        localStorage.setItem("BillingCountryName", countryName);
      }, [CountryIso3Code]); 




    return (
        <>
            <div className="card-container">
                <div className='Payment-sum'>
                    <div className="card-image">
                        <Flag code={CountryIso3Code} id='img-flag' />
                        <h3 style={{ color: '#4a4a4a' }}>{getCountryName(CountryIso3Code)}</h3>
                        <img src={Logo} id="navbarLogo" />

                    </div>

                    <div className="card-details">
                        <div className="card-info">
                            <h2 style={{ color: '#FFFFFF' }}>{PlanName}</h2>
                            <div className="info-item">
                                <span className="info-label"><WifiTetheringIcon />Coverage</span>
                                <span className="info-value">{getCountryName(CountryIso3Code)}</span>
                            </div>
                            <hr />
                            <div className="info-item">
                                <span className="info-label"><DataUsageIcon />Data</span>
                                {/* <span className="info-value">{((PlanData) / 1024).toFixed(2)} GB</span> */}
                                <span> {PlanData < 600
                                    ? `${PlanData} MB`
                                    : `${(PlanData / 1024).toFixed(0)} GB`}</span>
                            </div>
                            <hr />
                            <div className="info-item">

                                <span className="info-label"><FactCheckIcon />Validity</span>
                                <span className="info-value">{PlanValidity} Days</span>
                            </div>
                            <hr />
                            <div className="info-item">

                                <span className="info-label"><RequestQuoteIcon /> Price</span>
                                <span className="info-value" style={{ fontWeight: 'bolder', }}>$ {PlanPrice} USD</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Payment-Details'>
                    <div className='order-sum'>
                        <h2 style={{ color: '#4A4A4A' }}>Order Summary</h2>
                        <p>You can review your order summary.</p>
                        <hr style={{ marginTop: '2%', }} />
                    </div>
                    <div className='order-pri'>
                        <p>TOTAL PRICE</p>
                        <span>$ {PlanPrice} USD</span>
                    </div>
                    <hr style={{ marginTop: '2%' }} />
                    <div className='payment-section'>
                        <div className="box1">
                            <h2>Choose Payment Method</h2>
                            <p>You can choose or change the payment method to complete your order.</p>
                            <div className="card" onClick={handleOpen}>
                                <p>Choose Payment Method</p>
                                <ArrowForwardIosIcon style={{ color: '#8a8a8a', height: '18px', marginTop: '2%' }} />
                            </div>

                            <Modal
                                open={OpenPaymentMethodModal}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box  className="modal-box">
                                    <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <h3 style={{ color: '#4a4a4a' }}>Choose Payment Method</h3>
                                        <CancelIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                                    </div>
                                    <hr style={{ marginTop: '3%' }} />
                                    <h4 className="title-sub">Payment Methods</h4>
                                    <p className="title-sub-description">Please select your payment method down below.</p>

                                    <div className="card1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <p style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                src={card}
                                                alt="card"
                                                style={{
                                                    height: '40px',
                                                    width: '40px',
                                                    objectFit: 'contain',
                                                    marginRight: '8px'
                                                }}
                                            />
                                            <span> Credit / Debit Card <br /> <span>Visa, Mastercard, AMEX, CUP, JCB</span></span>
                                        </p>
                                        <Button variant="outlined"
                                            onClick={makePayment}
                                            style={{ color: '#4a4a4a', border: '1px solid #4a4a4a' }}>
                                            SELECT
                                        </Button>
                                    </div>

                                    {/* <div className="card1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <p style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                src={paypalImage}
                                                alt="PayPal"
                                                style={{
                                                    height: '40px',
                                                    width: '40px',
                                                    objectFit: 'contain',
                                                    marginRight: '8px'
                                                }}
                                            />
                                            PayPal
                                        </p>
                                        <Button
                                            //  variant="outlined" 
                                            style={{ color: '#4a4a4a', border: '1px solid #4a4a4a' }}>
                                            Coming Soon
                                        </Button>
                                    </div> */}

                                    {/* <div className="card1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <p style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                src={gpay}
                                                alt="Google Pay"
                                                style={{
                                                    height: '35px',
                                                    width: '35px',
                                                    objectFit: 'contain',
                                                    marginRight: '8px'
                                                }}
                                            />
                                            Google Pay
                                        </p>
                                        <Button
                                            // ref={expressCheckoutRef}
                                            // id="express-checkout-element"
                                            // variant="outlined"
                                            // onClick={makePayment}
                                            style={{ color: '#4a4a4a', border: '1px solid #4a4a4a' }}>
                                            Coming Soon
                                        </Button>




                                    </div> */}
                                </Box>
                            </Modal>

                        </div>
                        {/* <div className="box2">
                        <h2>Apply Code or Use Airmoney</h2>
                        <p>You can apply your discount / referral code or use Airmoney upon purchase.</p>
                        <div className="card" onClick={handleApplyOpen}>
                            <p>Apply Code / Use Airmoney</p>
                            <ArrowForwardIosIcon style={{ color: '#8a8a8a', height: '18px', marginTop: '2%' }} />
                        </div>

                        <Modal
                            open={OpenCouponModal}
                            onClose={handleApplyClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}> <h3 style={{ color: '#4a4a4a' }}>Apply Code / Use Airmoney</h3>
                                    <CancelIcon onClick={handleApplyClose} style={{ cursor: 'pointer' }} /></div>
                                <hr style={{ marginTop: '2%' }} />
                                <h4 class="title-sub">Apply Code</h4>
                                <p class="title-sub-description">Please enter your discount or referral code to apply.</p>

                                <div className="Apply-code">
                                    <TextField
                                        required
                                        id="filled-required"
                                        label="Code"
                                        variant="filled"
                                        className='text_field'
                                    />

                                    <Button className='button-apply' variant="contained">APPLY</Button>
                                </div>



                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', marginTop: '5%' }} >
                                    <hr style={{ border: '1px lightgrey solid', width: '35%' }} />
                                    <p style={{
                                        color: "#8a8a8a",
                                        fontSize: ".6875rem",
                                        fontWeight: '600'
                                    }} >
                                        OR USE AIRMONEY
                                    </p>
                                    <hr style={{ border: '1px lightgrey solid', width: '35%' }} />
                                </div>





                                <h4 class="title-sub">Use Airmoney</h4>
                                <p class="title-sub-description">You have a total of <span>USD $0.00</span>  Airmoney in your wallet.</p>

                                <div className="card2">
                                    <p>You don’t have enough Airmoney to use on this purchase. The minimum amount you can use is USD $1.00.</p>
                                </div>

                            </Box>
                        </Modal>
                    </div> */}
                    </div>

                    <FormControl component="fieldset" className='checkbox-section' >
                        <FormControlLabel
                            control={
                                <Checkbox
                                // checked={PromotionMail}
                                // onChange={() => setPromotionMail(!PromotionMail)}
                                />
                            }
                            label={
                                <Typography style={{ fontSize: ' .8375rem', color: '#8a8a8a', fontWeight: '400', }}>
                                    Before completing this order, please confirm your device is eSIM compatible and network-unlocked.
                                </Typography>

                            } />
                        <div><a href="https://help.digiesim.com/hc/en-us/articles/26948658022801-Which-phones-are-compatible-with-digiesim" target="_blank" style={{color:'#00a1e4'}}>Learn More</a></div>
                    </FormControl>

                    <div className='button-section'>
                        {/* <Button variant="contained" >COMPLETE ORDER</Button> */}
                    </div>
                </div>
            </div >
            <div id='searchFaq'>
                <FAQ />
            </div>
            <Footer />
        </>

    )
}

export default Payment
