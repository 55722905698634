import React from 'react';
import './WhyDigiesim.css';
import Avatar from '../../../Assets/GoogleLogo.png';
import Why_1 from '../../../Assets/Why_1.svg';
import Why_2 from '../../../Assets/Why_2.svg';
import Why_3 from '../../../Assets/Why_3.svg';
import Why_4 from '../../../Assets/Why_4.svg';
import { StarRateRounded } from '@mui/icons-material';

import Review_1 from '../../../Assets/Review_1.png'
import Review_2 from '../../../Assets/Review_2.png'

const WhyDigiesim = () => {
    return (
        <div className='WhyDigiesim' >
            <div className="WhySection">
                <h2 >Why digiesim?</h2>

                <div className="img-section">
                    <div className="WhyCard">
                        <img src={Why_1} alt="Save Money" />
                        <h2>One eSIM</h2>
                        <p>Single download, covers 200+ countries</p>
                    </div>

                    <div className="WhyCard">
                        <img src={Why_2} alt="WhatsApp" />
                        <h2>Stay connected</h2>
                        <p>Keep your existing WhatsApp number </p>
                    </div>

                    <div className="WhyCard">
                        <img src={Why_3} alt="WiFi" />
                        <h2>Wi-Fi hotspot</h2>
                        <p>Connect other devices and to share data</p>
                    </div>

                    <div className="WhyCard">
                        <img src={Why_4} alt="Simple Setup" />
                        <h2>Simple setup</h2>
                        <p>Quick, hassle-free setup with 24/7 support</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyDigiesim
